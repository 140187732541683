import React from 'react'
import BannerSwiper from './BannerSwiper.js';
import { Link } from 'gatsby'

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Lisa Hansen</h1>
      </header>
      <div className="content">
        <p>
          Capturing love in nature
        </p>
        <ul className="actions">
          <li>
            <a href="#contact" name="Contact" className="button next scrolly">
              Contact me
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="banner-swiper-wrapper">
      <BannerSwiper />
    </div>
  </section>
)

export default Banner
