import React from 'react'
import PropTypes from 'prop-types'

const Tile = ({header,caption,link,target,image,aos}) => {

  return (
      <article
        data-aos={aos && aos.effect}
        data-aos-offset={aos && aos.offset}
        data-aos-delay={aos && aos.delay}>
        <a
          aria-label={"Link to " + header}
          href={link}
          className="link primary"
          target={target || "_self"}
          rel={target === "_blank" ? "noreferrer" : null }
        >
          <div className="tile-img">
            {image}
          </div>
          <header className="major tile-link">
            <h3>{header}</h3>
            <p>{caption}</p>
          </header>
        </a>
      </article>);
}

Tile.propTypes = {
    header: PropTypes.string,
    caption: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.element
}

export default Tile
