import React from 'react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StaticImage } from "gatsby-plugin-image"

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';


SwiperCore.use([Autoplay, EffectFade]);

export default () => {
  return (
    <Swiper
      effect="fade"
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
    >
      <SwiperSlide>
        <StaticImage src='../assets/images/banner1.jpg' alt="Wild Poppy Family" />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage src='../assets/images/banner2.jpg' alt="Wild Poppy Engadgments" />
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage src='../assets/images/banner3.jpg' alt="Wild Poppy Nature" />
      </SwiperSlide>
    </Swiper>
  );
};
