import React from 'react'
import Helmet from 'react-helmet'
import Banner from '../components/Banner'
import Tile from '../components/Tile';
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Lisa Hansen Photography"
          meta={[
            { name: 'description', content: 'Wedding, Event, and Family photography in Ketchum Idaho and Sun Valley. I am PNW born & raised and followed my love for mountains and adventure to Idaho.' },
            { name: 'keywords', content: 'photography, weddings, family, photo, photos, camera, photographer, family photos, portraits' },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <Tile header="Wedding"
                  caption="Intimate Celebrations"
                  image={<StaticImage src="../assets/images/wedding.jpg" alt="Wedding Photos" />}
                  aos={{effect:"fade-up",delay:0}}
                  link={'https://wildpoppyphoto.pic-time.com/-reedmorgan/sneakpeek'}
                  target="_blank"/>

            <Tile header="Engagement"
                  caption="Adventurous Commitments"
                  image={<StaticImage src="../assets/images/engagement.jpg" alt="Engadgment Portraits" />}
                  aos={{effect:"fade-up",delay:500}}
                  link={"https://wildpoppyphoto.pic-time.com/-nina/sneakpeek"}
                  target="_blank"/>

            <Tile header="Family"
                  caption="Joyous Connections"
                  image={<StaticImage src="../assets/images/family.jpg" alt="Family Photos" />}
                  aos={{effect:"fade-up",delay:1000}}
                  link={'https://wildpoppyphoto.pic-time.com/-alytobymaternity/sneakpeek'}
                  target="_blank"/>

          </section>
          <section id="about">
            <div className="inner">
              <span className="image right" data-aos="fade-left">
                <StaticImage src="../assets/images/lisa-hansen-camera.jpg" alt="Lisa Hansen, Photagrapher" />
              </span>
              <header className="major">
                <h2>About Me</h2>
              </header>
              <p>
              I am PNW born & raised and followed my love for mountains and adventure to Idaho. My love for adventure is equally matched to the love I have for my family, friends across many states, dog- Scout and boyfriend- Ryan (who helped me make this beautiful website!). People who live their life in an authentic manner deeply inspire me; people that use their voice and talents to help lift others up and make the world a more equitable place for everybody.
              </p>
              <p>
              I first became interested in photography in highschool, shooting on film, losing sense of time working in the dark room, holding your breath as the image started to appear in the developer. It felt like magic. My photography philosophy is all about relationships. Capturing the moments that tell the story of the people, the landscape, the season and ultimately the love is why I love photography. I would be honored to capture your relationship and tell the story of your love!
              </p>
            </div>
          </section>
          <section id="two">
            <div className="image-section">
              <div className="top-image image-section--image" data-aos="fade-right">
                <StaticImage src="../assets/images/bottom-image.jpg" alt='Nature Photo'/>
              </div>
              <div className="bottom-image image-section--image" data-aos="fade-up">
                <StaticImage src="../assets/images/top-image.jpg" alt='Mother Daughter Portrait'/>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
